<script>
export default {
  props: ['color'],
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25.045" height="20.036" viewBox="0 0 25.045 20.036">
    <path
      id="Icon_material-mail-outline" data-name="Icon material-mail-outline"
      d="M25.541,6H5.5A2.5,2.5,0,0,0,3.013,8.5L3,23.532a2.512,2.512,0,0,0,2.5,2.5H25.541a2.512,2.512,0,0,0,2.5-2.5V8.5A2.512,2.512,0,0,0,25.541,6Zm0,17.532H5.5V11.009L15.523,17.27l10.018-6.261ZM15.523,14.766,5.5,8.5H25.541Z"
      transform="translate(-3 -6)" :fill="color ? color : '#EC168C'"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
