<script>
export default {
  props: ['color'],
}
</script>

<template>
  <svg
    id="Group_1009" data-name="Group 1009" xmlns="http://www.w3.org/2000/svg" width="84.847" height="51.052"
    viewBox="0 0 84.847 51.052"
  >
    <path
      id="Path_3315" data-name="Path 3315"
      d="M84.847,31.58a1.43,1.43,0,0,0-.273-.929l-3.715-5.627a9.228,9.228,0,0,0-7.813-4.207H63.977V15.9A6.6,6.6,0,0,0,57.366,9.29h-28.3a11.628,11.628,0,0,0-4.917-7.157A11.324,11.324,0,0,0,15.3.22,11.9,11.9,0,0,0,5.846,14.1,11.982,11.982,0,0,0,9.67,20.763H1.694a1.694,1.694,0,1,0,0,3.387H12.73v1.2h-4.1a1.694,1.694,0,0,0,0,3.387h4.1v1.2H1.694A1.788,1.788,0,0,0,0,31.69a1.7,1.7,0,0,0,1.694,1.694H12.73v4.261a6.484,6.484,0,0,0-3.5,5.627v1.147a1.7,1.7,0,0,0,1.694,1.694h9.834a6.81,6.81,0,0,0,2.95,3.879,6.635,6.635,0,0,0,3.66,1.038,7.8,7.8,0,0,0,1.584-.164,6.987,6.987,0,0,0,5.081-4.753H66.381a6.909,6.909,0,0,0,8.2,4.753,6.987,6.987,0,0,0,5.081-4.753h3.5a1.7,1.7,0,0,0,1.694-1.694Zm-3.387.492V42.726H79.821a6.916,6.916,0,0,0-13.549,0h-2.24V24.15h9.015a5.865,5.865,0,0,1,4.972,2.677ZM73.046,40.6a3.551,3.551,0,1,1-3.551,3.551A3.544,3.544,0,0,1,73.046,40.6Zm-12.4,2.131H34.2a6.587,6.587,0,0,0-.983-2.4H60.644ZM21.635,40.376a6.221,6.221,0,0,0-.983,2.4H12.73a2.981,2.981,0,0,1,2.9-2.4Zm-4.1-20a8.476,8.476,0,0,1-8.468-8.468A8.614,8.614,0,0,1,11.528,5.9a8.424,8.424,0,0,1,6.01-2.459h0A8.614,8.614,0,0,1,23.547,5.9a8.5,8.5,0,0,1-6.01,14.478Zm-1.42,13.058h1.748a1.694,1.694,0,0,0,0-3.387H16.117v-1.2h7.54a1.694,1.694,0,0,0,0-3.387h-7.54v-1.2H30.6a1.694,1.694,0,0,0,0-3.387H25.4a12,12,0,0,0,3.988-8.14H57.421a3.265,3.265,0,0,1,3.223,3.223V37.044H16.117ZM27.426,40.6a3.551,3.551,0,1,1-3.551,3.551A3.544,3.544,0,0,1,27.426,40.6Z"
      transform="translate(0 -0.004)" :fill="color ? color : '#EC168C'"
    />
    <path
      id="Path_3316" data-name="Path 3316"
      d="M132.112,81.124a1.936,1.936,0,0,0,1.912-1.912h0a1.912,1.912,0,1,0-3.824,0A1.9,1.9,0,0,0,132.112,81.124Z"
      transform="translate(-59.066 -35.07)" :fill="color ? color : '#EC168C'"
    />
    <path
      id="Path_3317" data-name="Path 3317"
      d="M48.612,81.124a1.936,1.936,0,0,0,1.912-1.912h0a1.912,1.912,0,1,0-1.912,1.912Z"
      transform="translate(-21.186 -35.07)" :fill="color ? color : '#EC168C'"
    />
    <path
      id="Path_3318" data-name="Path 3318"
      d="M29.606,10.4a1.12,1.12,0,0,0-1.147,1.147v4.7l-2.349,3.223a1.133,1.133,0,0,0,.219,1.584h0a1.05,1.05,0,0,0,.656.219,1.073,1.073,0,0,0,.929-.492l2.568-3.551a1.05,1.05,0,0,0,.219-.656V11.493A1,1,0,0,0,29.606,10.4Z"
      transform="translate(-11.74 -4.72)" :fill="color ? color : '#EC168C'"
    />
    <path
      id="Path_3319" data-name="Path 3319"
      d="M130.768,47.6h-1.694a.863.863,0,0,0-.874.874v6.174a.863.863,0,0,0,.874.874h5.9a1.471,1.471,0,0,0,1.311-.765,1.53,1.53,0,0,0,0-1.53l-2.021-3.606A4.087,4.087,0,0,0,130.768,47.6Zm-.82,6.174V49.4h.82a2.229,2.229,0,0,1,1.967,1.147l1.858,3.223Z"
      transform="translate(-58.159 -21.596)" :fill="color ? color : '#EC168C'"
    />
  </svg>
</template>

<style lang="less" scoped>

</style>
