<script>
import CrossIcon from '@/assets/svgs/cross.vue'
export default {
  components: {
    CrossIcon,
  },
  props: ['product'],
  methods: {
    removeFromCart(id) {
      this.$store.commit('removeProductFromCart', id)
    },
    adjustProductQty(type) {
      this.$store.commit('adjustProductQty', {
        type,
        product: this.product,
      })
    },
  },
}
</script>

<template>
  <div>
    <div class="d-flex justify-content-center w-100">
      <div
        class="
          bottleMenu__content__product__imgContainer
          bottleMenuMobileContainer__content__product__imgContainer
          position-relative
        "
      >
        <button
          class="p-0 btn bottleMenu__content__product__imgContainer__cross"
          @click="removeFromCart(product.id)"
        >
          <CrossIcon external-class="bottleMenu__content__product__imgContainer__cross__svg" />
        </button>
        <div class="bottleMenu__content__product__imgContainer__img">
          <img class="img-fluid" :src="`data:image/jpg;base64,${product.image}`" alt="">
        </div>
      </div>

      <div class="bottleMenu__content__product__header">
        <h1 class="bottleMenu__content__product__header__title text-uppercase">
          {{ product.title }}
        </h1>
        <p class="bottleMenu__content__product__header__size text-uppercase">
          {{ product.title }}
        </p>
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            bottleMenu__content__product__quantitybtnContainer
          "
        >
          <button
            class="p-0 btn bottleMenu__content__product__quantitybtn"
            @click="adjustProductQty('decrease')"
          >
            -
          </button>
          <p class="bottleMenu__content__product__quantity">
            {{ product.quantity }}
          </p>
          <button
            class="p-0 btn bottleMenu__content__product__quantitybtn"
            @click="adjustProductQty('increase')"
          >
            +
          </button>
        </div>
      </div>
      <h1 class="bottleMenu__content__product__price">
        <span>{{ product.price }}</span>
        <span class="bottleMenu__content__product__price__currency"> Dh</span>
      </h1>
    </div>

    <hr class="bottleMenu__content__product__hrline">
  </div>
</template>

<style lang="scss" scoped>

</style>
