import { render, staticRenderFns } from "./BottleMenuMobile.vue?vue&type=template&id=5fbb1926&scoped=true&"
import script from "./BottleMenuMobile.vue?vue&type=script&lang=js&"
export * from "./BottleMenuMobile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fbb1926",
  null
  
)

export default component.exports