<script>
import AppHeader from '@/components/Header.vue'
import AppFooter from '@/components/Footer.vue'
export default {
  components: { AppHeader, AppFooter },
  computed: {
    UserId() {
      return this.$store.state.user.id
    },
  },
  async mounted() {
    const token = this.$cookie.get('saisstoken')
    // this.$store.commit("clearState");

    if (token) {
      const user = await this.$store.dispatch('getUser', { url: 'auth/me' })
      await this.$store.dispatch('getCustomer', {
        url: `customers/${user.id}`,
      })
    }

    this.$store.commit('resetStates')
  },
}
</script>

<template>
  <div>
    <AppHeader />
    <router-view />
    <AppFooter />
  </div>
</template>

<style>
:root {
  --form-control-color: #ec168d;
  --form-control-disabled: #959495;
}
</style>
