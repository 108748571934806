<script>
export default { props: ['externalClass'] }
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg" :width="14.897" :height="14.897" viewBox="0 0 14.897 14.897"
    :class="externalClass"
  >
    <g id="Group_1204" data-name="Group 1204" transform="translate(7.449 20.932) rotate(-135)">
      <line
        id="Line_3" data-name="Line 3" y2="19.068" transform="translate(9.534 0)" fill="none" stroke="#ec168c"
        stroke-linecap="round" stroke-width="1"
      />
      <line
        id="Line_4" data-name="Line 4" x2="19.068" transform="translate(0 9.534)" fill="none" stroke="#ec168c"
        stroke-linecap="round" stroke-width="1"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>

</style>
