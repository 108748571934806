import axios from "axios";
import store from "../store/index";
import router from "../router";
import Vue from "vue";
export default function setup() {
  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      if (error.response.status === 403 || error.response.status === 401) {
        if (Vue.cookie.get("saisstoken")) {
          Vue.cookie.delete("saisstoken");

          store.commit("resetStates");
          store.commit("clearform1");
          store.commit("resetSteps");
          store.commit("clearUserData");
          store.commit("clearOrderData");
        }
        router.push("/authentication");
      }

      return Promise.reject(error);
    }
  );
}
