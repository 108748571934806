import Vue from 'vue'

// import { BootstrapVue } from 'bootstrap-vue'
import './assets/styles/typography.css'
import './assets/styles/main.scss'
import { ValidationProvider } from 'vee-validate'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import axios from 'axios'
import router from './router'
import interceptorSetup from './helpers/interceptors'
import App from './App.vue'
import store from './store'

import rules from './validation/rules'
const VueCookie = require('vue-cookie')
Vue.component('ValidationProvider', ValidationProvider)
Vue.use(PerfectScrollbar)
Vue.config.productionTip = false

Vue.use(VueCookie)
// axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : 'https://sidiharazem.ma/api/'
axios.defaults.baseURL = 'https://sidiharazem.ma/api/'

interceptorSetup()
rules()

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
