<script>
import CartProduct from '@/components/common/BottleMenuContent/CartProduct.vue'
import BottomContent from '@/components/common/BottleMenuContent/BottomContent.vue'

export default {
  components: {
    CartProduct,
    BottomContent,
  },
  data() {
    return {
      bottle: require('../../assets/images/Menu/bottleMenu.png'),
      bottlePack: require('../../assets/images/Menu/bottlePack.png'),
      cross: require('../../assets/images/Menu/cross.png'),
    }
  },
  computed: {
    products() {
      return this.$store.state.cart.products
    },
    cartQuantity() {
      return this.$store.state.cart.cartQuantity
    },
  },
}
</script>

<template>
  <div
    class="bottleMenuMobileContainer d-flex justify-content-center  position-fixed"
    :class="{ show: $store.state.showMenu }"
  >
    <button class="btn bottleMenuMobileContainer__crossBtn p-0" @click="$store.commit('showSidebar')">
      <img :src="cross" alt="">
    </button>

    <div class="bottleMenu mobile d-flex justify-content-center  position-absolute">
      <div class="position-relative d-flex justify-content-center">
        <img class="img-fluid bottleMenuMobileContainer__bottle" :src="bottle" alt="">
        <div v-if="cartQuantity > 0">
          <div class="position-absolute bottleMenu__content bottleMenuMobileContainer__content">
            <h1 class="text-uppercase bottleMenu__content__heading">
              Votre
            </h1>
            <h1 class="text-uppercase bottleMenu__content__heading">
              panier
            </h1>
            <div class="text-center bottleMenu__content__bottlePack position-relative">
              <img class="img-fluid" :src="bottlePack" alt="">
              <div
                class="bottleMenu__content__bottlePack__quanitity bottleMenuMobileContainer__content__bottlePack__quanitity  position-absolute"
              >
                {{ cartQuantity }}
              </div>
            </div>
            <div class="bottleMenu__content__product ">
              <perfect-scrollbar>
                <CartProduct v-for="product in products" :key="product.id" :product="product" />
              </perfect-scrollbar>
            </div>
          </div>
          <BottomContent />
        </div>
        <div v-else class="position-absolute bottleMenu__content" style="top: 36%;">
          <div class="text-center bottleMenu__content__bottlePack mb-2 position-relative">
            <img class="img-fluid" :src="bottlePack" alt="">
            <div class="bottleMenu__content__bottlePack__quanitity position-absolute">
              {{ cartQuantity }}
            </div>
          </div>
          <h1 class="text-uppercase bottleMenu__content__heading">
            Votre panier est vide
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
