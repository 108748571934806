<script>
export default {
  props: ['color'],
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25.522" height="25.522" viewBox="0 0 25.522 25.522">
    <path
      id="Icon_awesome-whatsapp" data-name="Icon awesome-whatsapp"
      d="M21.7,5.959A12.651,12.651,0,0,0,1.795,21.221L0,27.772l6.705-1.76A12.609,12.609,0,0,0,12.75,27.55h.006A12.769,12.769,0,0,0,25.522,14.9,12.7,12.7,0,0,0,21.7,5.959ZM12.755,25.419A10.493,10.493,0,0,1,7.4,23.955l-.382-.228L3.042,24.77,4.1,20.89l-.251-.4A10.532,10.532,0,1,1,23.386,14.9a10.629,10.629,0,0,1-10.63,10.517Zm5.765-7.873c-.313-.16-1.869-.923-2.159-1.025s-.5-.16-.712.16-.815,1.025-1,1.242-.37.239-.684.08a8.6,8.6,0,0,1-4.3-3.76c-.325-.558.325-.518.929-1.726a.586.586,0,0,0-.028-.553c-.08-.16-.712-1.715-.974-2.347-.256-.615-.518-.53-.712-.541s-.393-.011-.6-.011a1.171,1.171,0,0,0-.843.393,3.551,3.551,0,0,0-1.105,2.638,6.191,6.191,0,0,0,1.288,3.27,14.121,14.121,0,0,0,5.4,4.774c2.005.866,2.791.94,3.794.792a3.237,3.237,0,0,0,2.131-1.5,2.643,2.643,0,0,0,.182-1.5C19.045,17.78,18.834,17.7,18.521,17.546Z"
      transform="translate(0 -2.25)" :fill="color ? color : '#EC168C'"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
