import { extend } from 'vee-validate'

import {
  alpha,
  alpha_dash,
  alpha_num,
  alpha_spaces,
  between,
  confirmed,
  double,
  email,
  ext,
  image,
  is_not,
  max,
  numeric,
  regex,
  required,
} from 'vee-validate/dist/rules'

export default () => {
  return [
    extend('email', { ...email, message: 'Le courriel est invalide' }),
    extend('numeric', { ...numeric, message: 'valeur non valide fournie' }),
    extend('alpha_num', alpha_num),
    extend('alpha_spaces', alpha_spaces),
    extend('ext', ext),
    extend('is_not', is_not),
    extend('image', image, {
      message: 'Please upload an image',
    }),

    extend('required', {
      ...required,
      message: 'Ce champs est requis.',
    }),

    extend('between', between),
    extend('max', max),
    extend('alpha', alpha),
    extend('confirmed', confirmed),
    extend('isValidPhoneNumber', (value) => {
      const pattern = /^[+ 0-9]{11,13}$/
      return pattern.test(value) ? true : 'Phone number is invalid'
    }),
    extend('alpha_dash', alpha_dash),
    extend('double', double),
    extend('regex', regex),
  ]
}
