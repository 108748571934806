<script>
export default {
  data() {
    return {
      discountText: 'Vous avez un code de réduction ?',
      searchvalue: '',
      // totalproductprice: 34,
      // totalprice: 34,
    }
  },

  computed: {
    totalprice() {
      return this.$store.state.cart.total
    },
    subtotalprice() {
      return this.$store.state.cart.subtotal
    },
  },
  methods: {
    validate() {
      // do not proceed until price is minimum 150
      if (this.totalprice < 150)
        return

      this.$router.push({ name: 'multistepform' })

      this.$store.commit('showSidebar')
    },
  },
}
</script>

<template>
  <div class="bottleMenu__content__bottom" :class="{ alternate: totalprice < 150 }">
    <perfect-scrollbar>
      <!-- <p class=" bottleMenu__content__discountCodeText text-uppercase">
      {{ discountText }}
    </p>
    <div class="d-flex align-items-center justify-content-center mt-2 mb-3">
      <input
        class="bottleMenu__content__searchInput text-uppercase"
        type="text"
        v-model="searchvalue"
        placeholder="Vous avez un code de réduction ?"
      />
      <button class="btn bottleMenu__content__searchInput__btn text-uppercase">
        OK
      </button>
    </div>
    <hr /> -->
      <div class="bottleMenu__content__bottom__textContainer">
        <!-- <div
        class="d-flex bottleMenu__content__totalProductPriceContainer justify-content-between align-items-center"
      >
        <p
          class="bottleMenu__content__totalProductPriceContainer__text text-uppercase"
        >
          Total des produits
        </p>
        <p class="bottleMenu__content__totalProductPriceContainer__price">
          {{ subtotalprice.toFixed(2) }} Dh
        </p>
      </div> -->
        <div class="d-flex bottleMenu__content__offerContainer justify-content-between align-items-center">
          <p class="bottleMenu__content__offerContainer__text text-uppercase">
            Frais de livraison
          </p>
          <p class="bottleMenu__content__offerContainer__text text-uppercase">
            offert
          </p>
        </div>
        <div class="d-flex bottleMenu__content__totalContainer justify-content-between align-items-center">
          <p class="bottleMenu__content__totalContainer__text text-uppercase">
            TOTAL TTC
          </p>
          <p class="bottleMenu__content__totalContainer__price text-uppercase">
            {{ totalprice.toFixed(2) }} DH
          </p>
        </div>

        <div v-if="totalprice < 150">
          <p class="text-secondary bottleMenu__content__warning">
            Le montant de votre panier n’a pas atteint le minimum d’achat à
            savoir 150 DH, Veuillez ajouter des articles à votre commande afin
            de bénéficier la livraison gratuite.
          </p>
        </div>
        <div v-else>
          <p class="text-secondary bottleMenu__content__warning">
            (Quantité limitée à 30 unités par produit)
          </p>
        </div>

        <button
          class="d-block btn btn-outline-secondary text-uppercase bottleMenu__content__btnSecondary"
          @click="$store.commit('showSidebar')"
        >
          continuer mes achats
        </button>
        <button
          class="d-block btn btn-primary text-uppercase bottleMenu__content__btnPrimary"
          @click="validate"
        >
          Valider
        </button>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<style lang="scss" scoped>

</style>
