<script>
export default {
  props: ['color'],
}
</script>

<template>
  <svg
    id="Component_20_25" data-name="Component 20 – 25" xmlns="http://www.w3.org/2000/svg" width="50.903"
    height="50.903" viewBox="0 0 50.903 50.903"
  >
    <path
      id="Path_3302" data-name="Path 3302"
      d="M758.09,597.368H734.622a13.734,13.734,0,0,0-13.717,13.717v23.468a13.734,13.734,0,0,0,13.717,13.717H758.09a13.733,13.733,0,0,0,13.717-13.717V611.085A13.733,13.733,0,0,0,758.09,597.368Zm9.75,37.185a9.76,9.76,0,0,1-9.75,9.75H734.622a9.76,9.76,0,0,1-9.75-9.75V611.085a9.761,9.761,0,0,1,9.75-9.75H758.09a9.761,9.761,0,0,1,9.75,9.75Z"
      transform="translate(-720.905 -597.368)" :fill="color ? color : '#253A93'"
    />
    <path
      id="Path_3303" data-name="Path 3303"
      d="M744.668,608.061a11.375,11.375,0,1,0-1.694,22.627,11.45,11.45,0,0,0,1.694-.127,11.373,11.373,0,0,0,0-22.5Zm4.281,15.661a7.407,7.407,0,1,1-7.04-11.737,7.517,7.517,0,0,1,2.177,0,7.37,7.37,0,0,1,6.24,6.239h0A7.363,7.363,0,0,1,748.949,623.723Z"
      transform="translate(-717.448 -593.959)" :fill="color ? color : '#253A93'"
    />
    <path
      id="Path_3304" data-name="Path 3304"
      d="M748.992,605.926a2,2,0,0,0-.582,1.4,1.983,1.983,0,0,0,3.386,1.4,1.981,1.981,0,0,0,.582-1.4,2,2,0,0,0-.582-1.4A2.044,2.044,0,0,0,748.992,605.926Z"
      transform="translate(-711.034 -594.787)" :fill="color ? color : '#253A93'"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
