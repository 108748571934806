<script>
export default {
  props: ['color'],
}
</script>

<template>
  <svg
    id="Group_1122" data-name="Group 1122" xmlns="http://www.w3.org/2000/svg" width="64.066" height="53.457"
    viewBox="0 0 64.066 53.457"
  >
    <g id="Group_1007" data-name="Group 1007" transform="translate(0)">
      <path
        id="Path_3306" data-name="Path 3306"
        d="M0,4.267A6.037,6.037,0,0,1,1.363,1.6,4.957,4.957,0,0,1,4.86,0H47.472a4.855,4.855,0,0,1,4.919,3.378,6.02,6.02,0,0,1,.356,2.015c.059,2.845,0,5.689,0,8.593,0,.474.119.652.593.77a10.053,10.053,0,0,1,6.993,9.482v2.134h3.734V39.411a13.806,13.806,0,0,1-27.381,2.726c-.237-1.422-.237-2.845-.356-4.386H5.63c-2.963,0-4.682-1.3-5.512-4.149,0-.059-.059-.059-.059-.119C0,23.706,0,13.987,0,4.267ZM40.182,26.373V24.18a10.027,10.027,0,0,1,8.3-9.72c.415-.059.533-.237.533-.652V5.63c0-1.482-.356-1.837-1.778-1.837H5.571c-1.422,0-1.778.356-1.778,1.778V32.122c0,1.363.415,1.778,1.778,1.778H36.448V30.107H30.225V26.373Zm.059,3.793-.059.119c0,3.5-.119,6.993.119,10.49.3,4.563,4.563,8.534,9.127,8.771A10.1,10.1,0,0,0,60.273,39.352V30.166ZM56.48,26.314c0-.83.059-1.6,0-2.371a6.25,6.25,0,0,0-8.179-5.63c-3.437,1.126-4.8,4.149-4.326,8Z"
        :fill="color ? color : '#EC168C'"
      />
      <path
        id="Path_3307" data-name="Path 3307"
        d="M18.7,30.36H16.148a3.415,3.415,0,0,1-3.378-3.378c-.059-1.482-.059-3.023,0-4.5A3.418,3.418,0,0,1,16.325,19.1h4.8a3.4,3.4,0,0,1,3.556,3.437c.059,1.482.059,3.023,0,4.5a3.39,3.39,0,0,1-3.437,3.378c-.889-.059-1.719-.059-2.548-.059Zm2.134-7.467H16.5v3.674H20.83Z"
        transform="translate(-5.183 -7.78)" :fill="color ? color : '#EC168C'"
      />
      <path
        id="Path_3308" data-name="Path 3308" d="M31.9,48.274V44.6h7.467v3.674Z"
        transform="translate(-12.994 -18.168)" :fill="color ? color : '#EC168C'"
      />
      <path
        id="Path_3309" data-name="Path 3309"
        d="M12.7,48.334V44.6h7.527v3.378c0,.119-.237.3-.3.3C17.5,48.334,15.13,48.334,12.7,48.334Z"
        transform="translate(-5.173 -18.168)" :fill="color ? color : '#EC168C'"
      />
      <path
        id="Path_3310" data-name="Path 3310"
        d="M79.319,67.642,74.4,62.723l2.548-2.548c.77.77,1.541,1.6,2.371,2.43,1.719-1.541,3.319-3.023,5.038-4.5.83.948,1.659,1.837,2.489,2.785C84.3,63.138,81.808,65.39,79.319,67.642Z"
        transform="translate(-30.307 -23.667)" :fill="color ? color : '#EC168C'"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>

</style>
