<script>
// import MenuIcon from "@/assets/svgs/Menu.vue";
import PinkIcon from '@/assets/svgs/pinkBottle.vue'
import UserIcon from '@/assets/svgs/User.vue'
import BoxIcon from '@/assets/svgs/Box.vue'
import PlayIcon from '@/assets/svgs/Play.vue'
import BottleMenu from '@/components/common/BottleMenu.vue'
import BottleMenuMobile from '@/components/common/BottleMenuMobile.vue'
export default {
  components: {
    // MenuIcon,
    PinkIcon,
    UserIcon,
    BoxIcon,
    PlayIcon,
    BottleMenu,
    BottleMenuMobile,
  },
  data() {
    return {
      bottleText: 'nos produits',
      logo: require('../assets/images/header/LogoAS.svg'),
      retourText: 'retour',
    }
  },
  computed: {
    cartQuantity() {
      return this.$store.state.cart.cartQuantity
    },
  },
  methods: {
    toggleMenu() {
      // if current route is multistep/authentication do not open the cart
      if (this.$route.path === '/authentication')
        return

      this.$store.commit('showSidebar')
    },
    userRedirect() {
      const token = this.$cookie.get('saisstoken')

      if (token)
        this.$router.push('/orders')
      else this.$router.push('/authentication')
    },
  },
}
</script>

<template>
  <div class="header">
    <div class="container-fluid header__container d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <!-- <button class="btn p-0 header__menuIconContainer">
          <MenuIcon />
        </button> -->
        <router-link class="btn d-flex header__productIconBoxContainer m-0 p-0" :to="{ path: '/', hash: '#products' }">
          <PinkIcon />
          <p class="header__bottleText">
            {{ bottleText }}
          </p>
        </router-link>
      </div>

      <div class="d-flex align-items-center">
        <button class="btn p-0 header__userIconContainer" @click="userRedirect">
          <UserIcon />
        </button>
        <div class="header__boxIcon">
          <button class="position-relative btn p-0" @click="toggleMenu">
            <BoxIcon />
            <div class="header__boxIcon__quantity">
              {{ cartQuantity }}
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="header__logoContainer cursor-pointer pointer">
      <router-link class="header__logoContainer__logo logo-container" :to="{ path: '/' }">
        <img class="logo" :src="logo" alt="logo">
      </router-link>
    </div>
    <div v-if="$route.name !== 'ProductList'" class="header__subheaderContainer">
      <div class="header__subheader">
        <button class="btn header__subheader__retour d-flex" @click="$router.back()">
          <PlayIcon />
          <p class="header__subheader__retour__text text-uppercase">
            {{ retourText }}
          </p>
        </button>
      </div>
    </div>
    <div class="d-sm-block d-none">
      <BottleMenu />
    </div>
    <div class="d-sm-none d-block">
      <BottleMenuMobile />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logo-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 20px;
    padding: 10px;
    
    .logo{
        width: 100%;
    }
    
@media (min-width:575px) {
    margin-top: 30px;
    padding: 15px;

    }
}
</style>
