<script>
import Call from '@/assets/svgs/call.vue'
import Car from '@/assets/svgs/car.vue'
import Card from '@/assets/svgs/card.vue'
import Cash from '@/assets/svgs/cash.vue'
import Location from '@/assets/svgs/path.vue'
import Message from '@/assets/svgs/message.vue'
import Facebook from '@/assets/svgs/fb.vue'
import Insta from '@/assets/svgs/insta.vue'
import WA from '@/assets/svgs/whatsapp.vue'

import Lock from '@/assets/svgs/lock.vue'

export default {
  components: {
    Call,
    Car,
    Card,
    Cash,
    Location,
    Message,
    Facebook,
    Insta,
    Lock,
    WA,
  },
  data() {
    return {
      mobile: {
        saiss: require('../assets/images/header/LogoAS.svg'),
        cash: require('../assets/images/footer/mobile/cash.svg'),
        cards: require('../assets/images/footer/mobile/visa.svg'),
      },
      footerContent1: [
        {
          id: 'sewd2dcvvsz',
          heading: 'Service Conso',
          subheading: '06 08 051 051',
          icon: 'Call',
        },
        {
          id: 'szxd2dcvsxv',
          heading: 'Livraison rapide',
          subheading: 'En 48h',
          icon: 'Car',
        },
        {
          id: 'zxdcvfsde1',
          heading: 'Paiement sécurisé',
          subheading: 'Via CMI',
          icon: 'Card',
        },
        {
          id: 'xzcxdcvfsde2',
          heading: 'Paiement à la livraison',
          subheading: 'par carte ou en espèce',
          icon: 'Cash',
        },
      ],
      contacts: [
        {
          id: 'dasf2242asdzc',
          icon: 'Location',
          text: 'Usine de Aïn Saïss, km 15 route de Taza - Fes. Maroc',
        },
        {
          id: 'dasf2242asdzcz',
          icon: 'WA',
          text: '06 08 051 051',
        },
        {
          id: 'dasf2zcfwszc',
          icon: 'Message',
          text: 'contact@ain-saiss.ma',
        },
      ],
      social: {
        heading: 'Suivez-nous sur',
        fb: 'Facebook',
        insta: 'Insta',
      },
      visa: require('../assets/images/footer/visa.png'),
      logo1: require('../assets/images/header/LogoAS.svg'),
      copyrightText:
                '© Copyrights 2022  <span class=\'footer__copyright__alternate\'>Aïn Saïss</span>. Tous les droits sont réservés',
      conditionsText: 'Conditions générales de ventes',
    }
  },
  computed: {
    currentProps() {
      return { color: '#eeeeee' }
    },
  },
  methods: {
    iconColor: (color) => {
      return {
        color,
      }
    },
  },
}
</script>

<template>
  <div class="footerMain ">
    <div class="container-fluid ">
      <div class="row footer__content1__row d-lg-none m-0">
        <div v-for="content in footerContent1" :key="content.id" class="col-lg-3 col-6 pb-lg-0 pb-5">
          <div class="footer__content1 text-center">
            <h1 class="text-uppercase footer__content1__heading">
              {{ content.heading }}
            </h1>
            <p class="text-uppercase footer__content1__subheading">
              {{ content.subheading }}
            </p>
            <div class="footer__content1__icondivContainer d-flex justify-content-center position-relative">
              <component
                :is="content.icon" class="footer__content1__icondiv__icon" v-bind="currentProps"
                :class="content.icon"
              />
              <div class="footer__content1__icondiv" :class="content.icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div class="footer">
      <div class="footer__container">
        <!-- content1 -->
        <div class="row footer__content1__row d-lg-flex d-none ">
          <div v-for="content in footerContent1" :key="content.id" class="col-lg-3 col-6 pb-lg-0 pb-5">
            <div class="footer__content1 text-center">
              <h1 class="text-uppercase footer__content1__heading">
                {{ content.heading }}
              </h1>
              <p class="text-uppercase footer__content1__subheading">
                {{ content.subheading }}
              </p>
              <div class="d-flex justify-content-center position-relative">
                <component
                  :is="content.icon" class="footer__content1__icondiv__icon"
                  :class="content.icon"
                />
                <div class="footer__content1__icondiv" :class="content.icon" />
              </div>
            </div>
          </div>
        </div>
        <!-- content2 -->
        <div class="row footer__content2__row justify-content-between">
          <div class="col-xl-6 col-12 footer__content2__col1">
            <div class="d-flex w-100 footer__contact">
              <Location class="footer__contact__icon" />
              <div>
                <a href="https://goo.gl/maps/ARrdAAkyNvUUTt3o8" target="_blank" class="footer__contact__text">

                  Usine de Aïn Saïss, km 15 route de Taza - Fes. Maroc
                </a>
              </div>
            </div>
            <div class="d-flex w-100 footer__contact">
              <WA class="footer__contact__icon" />
              <div>
                <a href="tel:0608051051" target="_blank" class="footer__contact__text">
                  06 08 051 051
                </a>
              </div>
            </div>            <div class="d-flex w-100 footer__contact">
              <Message class="footer__contact__icon" />
              <div>
                <a href="mailto:contact@ain-saiss.ma" target="_blank" class="footer__contact__text">
                  contact@ain-saiss.ma
                </a>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-6 footer__content2__col2 text-center d-sm-block d-none">
            <div class="footer__social">
              <p class="footer__social__text">
                {{ social.heading }}
              </p>
              <div class="d-flex justify-content-between  footer__social__iconContainer">
                <a target="_blank" href="https://www.facebook.com/AinSaissOfficiel/">
                  <component :is="social.fb" />
                </a>
                <a target="_blank" href="https://www.instagram.com/ainsaissofficiel/">
                  <component :is="social.insta" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- content3 -->
        <div class="row footer__content3__row align-items-center d-sm-flex d-none">
          <div class="col-xl-6 col-12 order-xl-0 order-2 footer__content3__col1">
            <p class="footer__copyright" v-html="copyrightText" />
          </div>

          <div class="col-xl-3 col-6 text-center footer__content3__col2">
            <div class="footer__payments d-flex justify-content-xl-between">
              <Lock />
              <img class="img-fluid footer__payments__img mx-xl-0 mx-2" :src="visa" alt="">
              <Cash />
            </div>
          </div>

          <div class="col-xl-3 col-6 text-center footer__content3__col3">
            <a href="/terms" target="_blank" class="footer__conditions" v-html="conditionsText" />
          </div>
        </div>

        <!-- footer lower mobile content  -->
        <div class="footer__mobilContent">
          <div class="d-flex justify-content-between align-items-end">
            <img class="footer__mobilContent__cardcashimg" width="40%" :src="mobile.cards" alt="">
            <img class="footer__mobilContent__cardcashimg" width="20%" :src="mobile.cash" alt="">
            <div class="d-flex justify-content-between align-items-lg-center">
              <div class="footer__social">
                <p class="footer__social__text">
                  {{ social.heading }}
                </p>
                <div class="d-flex justify-content-between  footer__social__iconContainer">
                  <a target="_blank" href="https://www.facebook.com/AinSaissOfficiel/">
                    <component :is="social.fb" />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/ainsaissofficiel/">
                    <component :is="social.insta" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            class=" footer__mobilContent__logos d-flex align-items-center justify-content-center align-items-center"
          >
            <img class="img-fluid " width="30%" :src="mobile.saiss" alt="">
          </div>
          <div
            class=" footer__mobilContent__logos d-flex align-items-center justify-content-center align-items-center"
          >
            <a
              href="/terms" target="_blank" class="footer__conditions text-center"
              v-html="conditionsText"
            />
          </div>
          <p class="footer__copyright text-center" v-html="copyrightText" />
        </div>
      </div>
    </div>
    <div class="background" />
  </div>
</template>

<style lang="scss" scoped>
a:hover{
    text-decoration: underline;
}
</style>
