<script>
export default {
  props: ['color'],
}
</script>

<template>
  <svg
    id="Component_10_1" data-name="Component 10 – 1" xmlns="http://www.w3.org/2000/svg" width="51" height="51"
    viewBox="0 0 51 51"
  >
    <path
      id="Icon_material-local-phone" data-name="Icon material-local-phone"
      d="M9.277,14.781a19.992,19.992,0,0,0,8.7,8.7l2.9-2.9a1.312,1.312,0,0,1,1.346-.317,15.054,15.054,0,0,0,4.711.752,1.324,1.324,0,0,1,1.32,1.32v4.606a1.324,1.324,0,0,1-1.32,1.32A22.433,22.433,0,0,1,4.5,5.82,1.324,1.324,0,0,1,5.82,4.5h4.619a1.324,1.324,0,0,1,1.32,1.32,14.992,14.992,0,0,0,.752,4.711,1.324,1.324,0,0,1-.33,1.346Z"
      transform="translate(8.314 8.314)" :fill="color ? color : '#253A93'"
    />
    <g id="Ellipse_33" data-name="Ellipse 33" fill="none" :stroke="color ? color : '#EC168C'" stroke-width="3">
      <circle cx="25.5" cy="25.5" r="25.5" stroke="none" />
      <circle cx="25.5" cy="25.5" r="24" fill="none" />
    </g>
  </svg>
</template>
