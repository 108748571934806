<script>
export default {
  props: ['color'],
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="27.545" height="33.111" viewBox="0 0 27.545 33.111">
    <g id="Icon_feather-map-pin" data-name="Icon feather-map-pin" transform="translate(1.25 1.25)">
      <path
        id="Path_3272" data-name="Path 3272"
        d="M29.545,14.023c0,9.74-12.523,18.088-12.523,18.088S4.5,23.763,4.5,14.023a12.523,12.523,0,1,1,25.045,0Z"
        transform="translate(-4.5 -1.5)" fill="none" :stroke="color ? color : '#EC168C'" stroke-linecap="round"
        stroke-linejoin="round" stroke-width="2.5"
      />
      <path
        id="Path_3273" data-name="Path 3273"
        d="M21.848,14.674A4.174,4.174,0,1,1,17.674,10.5,4.174,4.174,0,0,1,21.848,14.674Z"
        transform="translate(-5.152 -2.152)" fill="none" :stroke="color ? color : '#EC168C'" stroke-linecap="round"
        stroke-linejoin="round" stroke-width="2.5"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>

</style>
