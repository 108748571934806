import { render, staticRenderFns } from "./pinkBottle.vue?vue&type=template&id=05141793&scoped=true&"
import script from "./pinkBottle.vue?vue&type=script&lang=js&"
export * from "./pinkBottle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05141793",
  null
  
)

export default component.exports