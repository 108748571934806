<script>
export default {}
</script>

<template>
  <svg id="XMLID_1_" xmlns="http://www.w3.org/2000/svg" width="25.297" height="36.296" viewBox="0 0 25.297 36.296">
    <path
      id="XMLID_3_"
      d="M22,15.134V9.349a9.349,9.349,0,1,0-18.7,0v5.785a12.649,12.649,0,1,0,18.7,0ZM12.649,3.3A6.06,6.06,0,0,1,18.7,9.349v3.19a12.652,12.652,0,0,0-12.1,0V9.349A6.06,6.06,0,0,1,12.649,3.3Zm0,29.7A9.349,9.349,0,1,1,22,23.647,9.355,9.355,0,0,1,12.649,33Z"
      fill="#b6f5fd"
    />
    <path
      id="XMLID_2_"
      d="M92.75,175a2.75,2.75,0,0,0-1.65,4.949v2.2a1.65,1.65,0,1,0,3.3,0v-2.2A2.75,2.75,0,0,0,92.75,175Z"
      transform="translate(-80.101 -155.752)" fill="#b6f5fd"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
