<script>
import CartProduct from '@/components/common/BottleMenuContent/CartProduct.vue'
import BottomContent from '@/components/common/BottleMenuContent/BottomContent.vue'

export default {
  components: {
    CartProduct,
    BottomContent,
  },
  data() {
    return {
      bottle: require('../../assets/images/Menu/bottleMenu.png'),
      bottlePack: require('../../assets/images/Menu/bottlePack.png'),
    }
  },
  computed: {
    products() {
      return this.$store.state.cart.products
    },
    cartQuantity() {
      return this.$store.state.cart.cartQuantity
    },
  },
}
</script>

<template>
  <div class="bottleMenu  position-absolute" :class="{ show: $store.state.showMenu }">
    <perfect-scrollbar>
      <div class="position-relative bottleMenu__bottleContainer">
        <img class="bottleMenu__bottleImage" :src="bottle" alt="">
        <div v-if="cartQuantity > 0">
          <div class="position-absolute bottleMenu__content">
            <h1 class="text-uppercase bottleMenu__content__heading">
              Votre
            </h1>
            <h1 class="text-uppercase bottleMenu__content__heading">
              panier
            </h1>
            <div class="text-center bottleMenu__content__bottlePack position-relative">
              <img class="img-fluid" :src="bottlePack" alt="">
              <div class="bottleMenu__content__bottlePack__quanitity position-absolute">
                {{ cartQuantity }}
              </div>
            </div>
            <div class="bottleMenu__content__product ">
              <perfect-scrollbar>
                <CartProduct v-for="product in products" :key="product.id" :product="product" />
              </perfect-scrollbar>
            </div>
          </div>
          <BottomContent />
        </div>
        <div v-else class="position-absolute bottleMenu__content" style="top: 36%;">
          <div class="text-center bottleMenu__content__bottlePack mb-2 position-relative">
            <img class="img-fluid" :src="bottlePack" alt="">
            <div class="bottleMenu__content__bottlePack__quanitity position-absolute">
              {{ cartQuantity }}
            </div>
          </div>
          <h1 class="text-uppercase bottleMenu__content__heading">
            Votre panier est vide
          </h1>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<style lang="scss" scoped>

</style>
