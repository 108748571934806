<script>
export default {
  props: ['color'],
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="51.216" height="50.902" viewBox="0 0 51.216 50.902">
    <path
      id="Path_3301" data-name="Path 3301"
      d="M688.622,597.243a25.608,25.608,0,0,0-4,50.9v-17.9h-6.505v-7.4h6.505v-5.642c0-6.418,3.821-9.963,9.671-9.963a39.559,39.559,0,0,1,5.734.5v6.305H696.8c-3.181,0-4.173,1.974-4.173,4v4.806h7.1l-1.136,7.4h-5.969v17.893a25.608,25.608,0,0,0-4-50.9Z"
      transform="translate(-663.018 -597.243)" :fill="color ? color : '#253A93'"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
