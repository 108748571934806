<script>
export default {
  props: ['color'],
}
</script>

<template>
  <svg
    id="Group_1121" data-name="Group 1121" xmlns="http://www.w3.org/2000/svg" width="87.867" height="38.657"
    viewBox="0 0 87.867 38.657"
  >
    <g id="Group_1008" data-name="Group 1008">
      <path
        id="Path_3311" data-name="Path 3311"
        d="M73.715,20.866a12,12,0,0,0-2.111.784,16.405,16.405,0,0,1-8.925,3.076,62.231,62.231,0,0,1-6.573-.663V36.486c0,1.568-.6,2.171-2.171,2.171H2.131c-1.568,0-2.171-.6-2.231-2.171V9.287c0-1.628.6-2.231,2.292-2.231H29.933a3.281,3.281,0,0,0,2.292-.844c1.93-1.568,3.92-3.076,5.789-4.7A7.3,7.3,0,0,1,42.537,0c7.84.06,15.74.06,23.58,0a9.95,9.95,0,0,1,5.247,1.266c.724.422,1.508.724,2.292,1.086C73.957.362,74.379,0,76.369,0H85.6c1.568,0,2.171.6,2.171,2.171v20.2c0,1.568-.6,2.171-2.171,2.171H76.248c-1.99,0-2.533-.482-2.533-2.533ZM3.518,35.1h49.09V18.635a.833.833,0,0,0-.241-.181L51.1,19.9a5.246,5.246,0,1,1-7.418-7.418c.543-.543,1.146-1.086,1.93-1.809H3.518Q3.518,22.8,3.518,35.1Zm70.2-17.429c0-3.015-.121-5.789.06-8.564.121-1.99-.362-3.317-2.412-3.92A24.947,24.947,0,0,1,68.77,3.98a5.346,5.346,0,0,0-1.689-.422H42.3a2.18,2.18,0,0,0-1.266.241c-1.327.965-2.653,2.05-3.92,3.076,3.2.241,6.332.181,9.468.241,1.447,0,2.774,0,3.8-1.327a1.544,1.544,0,0,1,2.352,0,1.725,1.725,0,0,1,.06,2.473c-.181.241-.482.482-.663.724-1.99,1.99-4.041,3.92-5.91,6.031a2.7,2.7,0,0,0-.543,1.93,1.772,1.772,0,0,0,1.266,1.086,2.473,2.473,0,0,0,1.809-.784c2.533-2.412,4.945-4.885,7.357-7.357a2.355,2.355,0,0,1,3.015-.663,9.4,9.4,0,0,0,5.126.784c1.206-.181,2.292-.663,3.5-.965a1.72,1.72,0,0,1,2.231,1.025,1.643,1.643,0,0,1-.965,2.231c-2.774,1.146-5.729,1.87-8.624.9a2.881,2.881,0,0,0-3.739,1.206,2.525,2.525,0,0,0-.482,1.266c-.06,1.146,0,2.352-.06,3.5-.06.663.241.9.784,1.086a12.353,12.353,0,0,0,11.76-.9C70.218,18.333,71.6,17.187,73.715,17.67Zm10.493,3.377V3.618H77.334V21.047Z"
        transform="translate(0.1 0)" :fill="color ? color : '#EC168C'"
      />
      <path
        id="Path_3312" data-name="Path 3312"
        d="M41.1,41.091a7,7,0,1,1,7-7A7,7,0,0,1,41.1,41.091Zm0-10.312a3.377,3.377,0,1,0,3.377,3.377A3.372,3.372,0,0,0,41.1,30.779Z"
        transform="translate(-13.475 -10.757)" :fill="color ? color : '#EC168C'"
      />
      <path
        id="Path_3313" data-name="Path 3313"
        d="M23.787,40.235H23.3a.927.927,0,0,1-.9-.9V34.2a.927.927,0,0,1,.9-.9h.482a.927.927,0,0,1,.9.9v5.126A.926.926,0,0,1,23.787,40.235Z"
        transform="translate(-8.831 -13.218)" :fill="color ? color : '#EC168C'"
      />
      <path
        id="Path_3314" data-name="Path 3314"
        d="M66.587,39.935H66.1a.927.927,0,0,1-.9-.9V33.9a.927.927,0,0,1,.9-.9h.482a.927.927,0,0,1,.9.9v5.126A.927.927,0,0,1,66.587,39.935Z"
        transform="translate(-25.82 -13.099)" :fill="color ? color : '#EC168C'"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>

</style>
