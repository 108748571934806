import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

Vue.use(Vuex)
const ls = new SecureLS({ isCompression: false })

const getGTMData = (product) => {
  const pid = product.id
  const name = product.title
  const price = product.price
  const quantity = product.quantity
  const sum = product.sum
  return { pid, name, price, quantity, sum }
}
const gtm_beginCheckout = (products) => {
  const items = []
  for (const product of products) {
    const { pid, name, price, quantity } = getGTMData(product)
    items.push(
      {
        index: pid,
        item_id: pid,
        item_name: name,
        price,
        currency: 'MAD',
        item_brand: 'AIN_SAISS',
        quantity,
      },
    )
  }
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      items,
    },
  })
  // console.log({
  //   event: "begin_checkout",
  //   ecommerce: {
  //     items: items
  //   }
  // })
}
const gtm_viewCart = (cart) => {
  let total = 0
  const items = []
  // return
  for (const product of cart.products) {
    const { pid, name, price, quantity, sum } = getGTMData(product)
    items.push(
      {
        index: pid,
        item_id: pid,
        item_name: name,
        price,
        currency: 'MAD',
        item_brand: 'AIN_SAISS',
        quantity,
      },
    )
    total += sum
  }

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'view_cart',
    ecommerce: {
      currency: 'MAD',
      value: total,
      items,
    },
  })
  // console.log({
  //   event: "view_cart",
  //   ecommerce: {
  //     currency: "MAD",
  //     value: total,
  //     items: items
  //   }
  // })
}
const gtm_addToCart = (product) => {
  const { pid, name, price, sum, quantity } = getGTMData(product)
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          index: pid,
          item_id: pid,
          item_name: name,
          price,
          currency: 'MAD',
          item_brand: 'AIN_SAISS',
          quantity: 1,
        },
      ],
    },
  })
  // console.log({
  //   event: "add_to_cart",
  //   ecommerce: {
  //     items: [
  //       {
  //         index: pid,
  //         item_id: pid,
  //         item_name: name,
  //         price: price,
  //         currency: "MAD",
  //         item_brand: "AIN_SAISS",
  //         quantity: 1
  //       }
  //     ]
  //   }
  // })
}
const gtm_removeFromCart = (product, all) => {
  const { pid, name, price, quantity } = getGTMData(product)
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      items: [
        {
          index: pid,
          item_id: pid,
          item_name: name,
          price,
          currency: 'MAD',
          item_brand: 'AIN_SAISS',
          quantity: all ? quantity : 1,
        },
      ],
    },
  })
  // console.log({
  //   event: "remove_from_cart",
  //   ecommerce: {
  //     items: [
  //       {
  //         index: pid,
  //         item_id: pid,
  //         item_name: name,
  //         price: price,
  //         currency: "MAD",
  //         item_brand: "AIN_SAISS",
  //         quantity: all ? quantity : 1
  //       }
  //     ]
  //   }
  // })
}
const gtm_addShippingInfo = (info) => {
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: info,
  })
  // console.log({
  //   event: "add_shipping_info",
  //   ecommerce: info
  // })
}
const gtm_purchase = (orderNumber, products) => {
  let total = 0
  const items = []
  for (const product of products) {
    const { pid, name, price, sum, quantity } = getGTMData(product)
    items.push(
      {
        index: pid,
        item_id: pid,
        item_name: name,
        price,
        currency: 'MAD',
        item_brand: 'AIN_SAISS',
        quantity,
      },
    )
    total += sum
  }
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: orderNumber,
      value: total,
      currency: 'MAD',
      items,
    },
  })
  // console.log({
  //   event: "purchase",
  //   ecommerce: {
  //     transaction_id: orderNumber,
  //     value: total,
  //     currency: "MAD",
  //     items: items
  //   }
  // })
}

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  state: {
    currentStep: 'Step1',
    currentHeading: 'Authentification',
    currentStepNumber: 1,
    showMenu: false,
    // productsData: {},

    page1form: {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      civility: null,
      birthDate: null,
      phoneNumber: null,
      originWebsite: 'AIN_SAISS',

      addresses: [
        {
          street: null,
          region: {
            id: '',
          },
          city: {
            id: '',
          },
          zone: {
            id: '',
          },
          addressType: 'Facturation',
        },
        {
          street: null,
          region: {
            id: '',
          },
          city: {
            id: '',
          },
          zone: {
            id: '',
          },
          addressType: 'Livraison',
        },
      ],
    },
    regions: [
      {
        value: null,
        text: 'Select Region',
      },
    ],
    cities: [
      {
        value: null,
        text: 'Select City',
      },
    ],
    zones: [
      {
        value: null,
        text: 'Select Zone',
      },
    ],
    status: null,
    singleproduct: {},
    user: {},
    customer: {},
    cart: {
      products: [],
      subtotal: 0,
      total: 0,
      cartQuantity: 0,
    },
    order: {
      paymentMode: '',
      comment: '',
      orderItems: [],
      addressLivraison: {
        street: '',
        region: {
          id: null,
        },
        city: {
          id: null,
        },
        zone: {
          id: null,
        },
      },
    },
    step2form: {
      city: null,
      comment: '',
      firstName: '',
      lastName: '',
      region: null,
      street: '',
      telephone: '',
      zone: null,
    },
    postOrderData: {},
    mode: '',
  },
  mutations: {
    clearUserData(state) {
      state.customer = {}
      state.user = {}
    },
    clearOrderData(state) {
      state.postOrderData = {}
    },
    resetStates(state, payload) {
      state.showMenu = false
      state.status = null
    },
    changeStep(state, payload) {
      state.currentStep = payload.name
      state.currentStepNumber = payload.number
      state.currentHeading = payload.heading
      // console.log(payload.number)
      if (payload.number === 2) {
        console.log('GTM Begin Checkout')
        gtm_beginCheckout(state.cart.products)
      }
      else if (payload.number === 3) {
        console.log('GTM Add shipping info')
        gtm_addShippingInfo(state.user)
      }
    },
    showSidebar(state) {
      state.showMenu = !state.showMenu
      if (state.showMenu) {
        console.log('GTM View cart')
        gtm_viewCart(state.cart)
      }
    },

    saveInput(state, payload) {
      if (payload.from == 'page1') {
        if (
          payload.title == 'region'
          || payload.title == 'zone'
          || payload.title == 'city'
        ) {
          state.page1form.addresses[0][payload.title].id = payload.value
          state.page1form.addresses[1][payload.title].id = payload.value
          return
        }
        else if (payload.title == 'street') {
          state.page1form.addresses[0][payload.title] = payload.value
          state.page1form.addresses[1][payload.title] = payload.value
          return
        }

        if (state.page1form.hasOwnProperty(payload.title))
          state.page1form[payload.title] = payload.value
      }
    },
    clearform1(state) {
      state.page1form = {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        civility: null,
        birthDate: null,
        phoneNumber: null,
        originWebsite: 'AIN_SAISS',

        addresses: [
          {
            street: null,
            region: {
              id: '',
            },
            city: {
              id: '',
            },
            zone: {
              id: '',
            },
            addressType: 'Facturation',
          },
          {
            street: null,
            region: {
              id: '',
            },
            city: {
              id: '',
            },
            zone: {
              id: '',
            },
            addressType: 'Livraison',
          },
        ],
      }
    },
    setRegion(state, payload) {
      state.regions = [...payload]
    },
    setCities(state, payload) {
      const regions = state.regions

      regions.some((region) => {
        if (region.id == payload)
          return (state.cities = [...region.cities])
      })
    },
    setZones(state, payload) {
      const cities = state.cities
      cities.some((city) => {
        if (city.id == payload)
          return (state.zones = [...city.zones])
      })
    },
    setStatus(state, payload) {
      state.status = payload.status
    },
    getSingleProduct(state, payload) {
      state.singleproduct = payload
    },
    // Filter products
    filterProducts(state, filter) {
      if (filter.id === -1) {
        state.filtered_product_ids = state.products.map(({ product_id }) => product_id)
      }
      else {
        const filtered = state.products.filter(({ categories }) => categories.includes(filter.id)).map(({ product_id }) => product_id)
        state.filtered_product_ids = filtered
      }
    },
    // Cart related functionality
    addProductToCart(state, product) {
      const qt_limit = 30
      if (state.cart.total)
        state.cart.total = Math.max(state.cart.total, 0)

      else
        state.cart.total = 0

      // console.log(state.cart.total)
      const foundProduct = state.cart.products.find(
        item => item.id == product.id,
      )
      const index = state.cart.products.findIndex(
        item => item.id == product.id,
      )
      // console.log("GTM Add to cart - id: " + product.id)
      gtm_addToCart(product)
      // general state of cart for both conditions

      // product found Index
      if (index >= 0) {
        if (foundProduct.quantity + product.quantity <= qt_limit) {
          foundProduct.quantity += product.quantity
          foundProduct.sum += product.quantity * product.price
          state.cart.products.splice(index, 1, foundProduct)
          state.cart.total += product.quantity * parseFloat(product.price)
          state.cart.subtotal += product.quantity * parseFloat(product.price)
        }
        else {
          product.quantity = qt_limit - foundProduct.quantity
          foundProduct.quantity += product.quantity
          foundProduct.sum += product.quantity * product.price
          state.cart.products.splice(index, 1, foundProduct)
          state.cart.total += product.quantity * parseFloat(product.price)
          state.cart.subtotal += product.quantity * parseFloat(product.price)
        }
        // since product already exist we increase cart total price

        return
      }

      // if product not found (adding new product)
      state.cart.products.push(product)
      product.quantity = Math.min(qt_limit, product.quantity)
      state.cart.cartQuantity += 1
      state.cart.total += product.quantity * parseFloat(product.price)
      state.cart.subtotal += product.quantity * parseFloat(product.price)
    },
    removeProductFromCart(state, productId) {
      if (state.cart.total)
        state.cart.total = Math.max(state.cart.total, 0)

      else
        state.cart.total = 0

      const product = state.cart.products.find(prod => prod.id == productId)

      // console.log("GTM Remove from cart - id: " + productId);
      gtm_removeFromCart(product, true)

      state.cart.total = state.cart.total - +product.quantity * +product.price

      state.cart.subtotal
        = state.cart.subtotal - +product.quantity * +product.price

      const index = state.cart.products.findIndex(item => item.id == productId)

      state.cart.products.splice(index, 1)

      state.cart.cartQuantity -= 1
    },
    adjustProductQty(state, payload) {
      const qt_limit = 30
      if (state.cart.total)
        state.cart.total = Math.max(state.cart.total, 0)

      else
        state.cart.total = 0

      const product = state.cart.products.find(
        item => item.id == payload.product.id,
      )
      const index = state.cart.products.findIndex(
        item => item.id == payload.product.id,
      )

      if (product && index >= 0) {
        if (payload.type == 'increase' && product.quantity < qt_limit) {
          product.quantity += 1
          product.sum += product.price
          state.cart.total += +product.price
          state.cart.subtotal += +product.price
          // console.log("GTM Add to cart - id: " + product.id)
          gtm_addToCart(product)
        }
        else if (payload.type == 'decrease') {
          product.quantity -= 1
          product.sum -= product.price
          state.cart.total -= +product.price
          state.cart.subtotal -= +product.price
          // console.log("GTM Remove from cart - id: " + product.id)
          gtm_removeFromCart(product, false)
        }
        state.cart.products.splice(index, 1, product)
      }
      if (product) {
        if (product.quantity <= 0) {
          state.cart.cartQuantity = state.cart.cartQuantity - 1
          state.cart.products.splice(index, 1)
        }
      }
    },
    clearCart(state) {
      state.cart = {
        products: [],
        subtotal: 0,
        total: 0,
        cartQuantity: 0,
      }
    },

    resetSteps(state) {
      state.currentStep = 'Step1'
      state.currentHeading = 'Authentification'
      state.currentStepNumber = 1
    },
    setstep2Data(state, payload) {
      state.step2form = payload
    },
    storeFinalData(state, mode) {
      // step3
      state.mode = mode // to check in component which mode was selected
      if (state.cart.cartQuantity > 0) {
        const orderItems = state.cart.products.map((item) => {
          return {
            productId: item.id,
            quantity: item.quantity,
            price: item.price * item.quantity,
          }
        })
        state.order.paymentMode = mode
        state.order.comment = state.step2form.comment
        state.order.orderItems = orderItems
        state.order.addressLivraison.street = state.step2form.street
        state.order.addressLivraison.region.id = state.step2form.region
        state.order.addressLivraison.city.id = state.step2form.city
        state.order.addressLivraison.zone.id = state.step2form.zone
      }
      // state.order.
    },
  },
  actions: {
    async getProducts({ commit }, payload) {
      try {
        const { data } = await axios.get(payload)
        // state.productsData = payload;
        return Promise.resolve(data)
      }
      catch (error) {
        console.log(error)
        commit('setStatus', { status: error.response.data.errorMessage })
      }
    },

    async getSingleProduct({ commit }, payload) {
      try {
        const { data } = await axios.get(payload.url)

        commit('getSingleProduct', data)
        return Promise.resolve(data)
      }
      catch (error) {
        commit('setStatus', { status: error.response.data.errorMessage })
      }
    },

    async setRegion({ commit }, payload) {
      try {
        const { data } = await axios.get(payload)

        commit('setRegion', data)
      }
      catch (error) {
        commit('setStatus', { status: error.response.data.errorMessage })
      }
    },
    async addCustomer({ commit, state }, payload) {
      try {
        const res = await axios.post(payload.url, state.page1form)

        commit('clearform1')
        return Promise.resolve(res)
      }
      catch (error) {
        commit('setStatus', { status: error.response.data.errorMessage })
      }
    },
    async setCustomer({ commit, state }, payload) {
      const token = Vue.cookie.get('saisstoken')
      try {
        const res = await axios.put(
          `${payload.url}/${state.user.id}`,
          payload.form,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        if (res.status == 204)
          commit('setStatus', { status: 'data updated successfully' })

        commit('clearform1')
        return Promise.resolve(res.status)
      }
      catch (error) {
        if (error.response.data.errorMessage)
          commit('setStatus', { status: error.response.data.errorMessage })

        else
          commit('setStatus', { status: error })
      }
    },
    async updateStep2Data({ commit, state }, payload) {
      const payloadform = payload.form
      const formdata = {
        firstName: payloadform.firstName,
        lastName: payloadform.lastName,
        phoneNumber: payloadform.telephone,
        email: state.customer.email,
        birthDate: state.customer.birthDate,
        civility: state.customer.civility,
        addresses: [
          {
            street: payloadform.street,
            region: {
              id: payloadform.region,
            },
            city: {
              id: payloadform.city,
            },
            zone: {
              id: payloadform.zone,
            },
            addressType: 'Facturation',
          },
          {
            street: payloadform.street,
            region: {
              id: payloadform.region,
            },
            city: {
              id: payloadform.city,
            },
            zone: {
              id: payloadform.zone,
            },
            addressType: 'Livraison',
          },
        ],
      }

      const token = Vue.cookie.get('saisstoken')

      try {
        const res = await axios.put(`${payload.url}/${state.user.id}`, formdata, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (res.status == 204) {
          const { data } = await axios.get(`/customers/${state.user.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          state.customer = data
          // console.log(state.customer);
          commit('setStatus', { status: 'data updated successfully' })
          if (data)
            return Promise.resolve(res.status)
        }
      }
      catch (error) {
        console.log(error)
        if (error.response.data.errorMessage)
          commit('setStatus', { status: error.response.data.errorMessage })

        else
          commit('setStatus', { status: error })
      }
    },
    async getCustomer({ commit, state }, payload) {
      const token = Vue.cookie.get('saisstoken')
      state.customer = {}

      try {
        const { data } = await axios.get(`${payload.url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        state.customer = data
        commit('clearform1')
        return Promise.resolve(data)
      }
      catch (error) {
        console.log(error)
        if (error.response.data.errorMessage)
          commit('setStatus', { status: error.response.data.errorMessage })

        else
          commit('setStatus', { status: error })
      }
    },

    // ==============================user auth related stuff
    // ===================get user info
    async getUser({ commit, state }, payload) {
      const token = Vue.cookie.get('saisstoken')
      try {
        const { data } = await axios.get(payload.url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        state.user = {
          id: data.id,
          firstName: data.firstName,
          email: data.email,
          lastName: data.lastName,
          address: data.addresses,
          street: data.addresses[0].street,
          region: {
            value: data.addresses[0].region.id,
            text: data.addresses[0].region.name,
          },
          city: {
            value: data.addresses[0].city.id,
            text: data.addresses[0].city.name,
          },
          zone: {
            value: data.addresses[0].zone.id,
            text: data.addresses[0].zone.name,
          },
          telephone: data.phoneNumber,
        }

        const res = await axios.get(`customers/${data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        state.customer = res.data

        return Promise.resolve(state.user)
      }
      catch (error) {
        commit('setStatus', { status: error.response.data.errorMessage })
      }
    },

    // Login
    async login({ commit, state }, payload) {
      try {
        const { data } = await axios.post(payload.url, payload.data)

        if (data.jwtToken) {
          Vue.cookie.set('saisstoken', data.jwtToken)
          const res = await axios.get('auth/me', {
            headers: {
              Authorization: `Bearer ${data.jwtToken}`,
            },
          })

          state.user = {
            id: res.data.id,
            firstName: res.data.firstName,
            email: res.data.email,
            lastName: res.data.lastName,
            address: res.data.addresses,
            street: res.data.addresses[0].street,
            region: {
              value: res.data.addresses[0].region.id,
              text: res.data.addresses[0].region.name,
            },
            city: {
              value: res.data.addresses[0].city.id,
              text: res.data.addresses[0].city.name,
            },
            telephone: res.data.phoneNumber,
          }

          const customer = await axios.get(`customers/${res.data.id}`, {
            headers: {
              Authorization: `Bearer ${data.jwtToken}`,
            },
          })
          state.customer = customer.data

          return Promise.resolve(data.jwtToken)
        }
      }
      catch (error) {
        commit('setStatus', { status: error.response.data.errorMessage })
      }
    },

    // place order
    async placeOrder({ commit, state }, payload) {
      // commit("setOrderForm");
      state.order.period = payload.isPeriodic
      console.log('PAYLOAD: ', payload.isPeriodic)
      // console.log(state.order)
      const token = Vue.cookie.get('saisstoken')
      try {
        const { data } = await axios.post(`${payload.url}`, state.order, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        const orderId = data

        console.log('GTM Purchase')
        gtm_purchase(orderId, state.cart.products)
        if (payload.paymentinfo) {
          // payment info
          const paymentArray = []
          const paymentForm = { ...payload.paymentinfo }

          paymentForm.amount = state.cart.total.toFixed(2).toString()
          paymentForm.BillToName = `${state.customer.lastName} ${state.customer.firstName}`
          paymentForm.tel = state.customer.phoneNumber.toString()
          paymentForm.email = state.customer.email

          paymentForm.oid = orderId.toString()
          paymentForm.okUrl = `${window.location.origin}/success?order=${orderId}`
          Object.entries(paymentForm).map(([k, v]) => {
            paymentArray.push({ name: k, value: v })
          })
          // console.log(paymentArray)

          const resp = await fetch(`${window.location.origin}/api/payment/hash`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({ input_valueArray: paymentArray }),
          })

          const hash = await resp.text()

          paymentForm.hash = hash

          return Promise.resolve({ paymentForm, data })
        }
        else {
          return Promise.resolve({ orderId })
        }
      }
      catch (error) {
        console.log(error)
        if (error.response.data.errorMessage)
          commit('setStatus', { status: error.response.data.errorMessage })

        else
          commit('setStatus', { status: error })
      }
    },
    async getOrderId({ commit, state }, orderId) {
      const token = Vue.cookie.get('saisstoken')
      const customer = await axios.get('/auth/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const customerId = customer.data.id

      let customerOrders = await axios.get(`/customers/${customerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      customerOrders = customerOrders.data.orders
      let postOrderData

      for (let i = 0; i < customerOrders.length; i++) {
        const currentOrder = customerOrders[i]
        if (currentOrder.id == orderId) {
          postOrderData = currentOrder
          break
        }
      }
      // let res = await axios.get(`/orders/${orderId}`, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      return postOrderData.orderNumber
    },
  },
  modules: {},
})
