import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: "/",
  //   name: "Page1",
  //   component: Page1,
  // },
  {
    path: '/',
    name: 'ProductList',
    component: () =>
      import(/* webpackChunkName: "ProductList" */ '../views/ProductList.vue'),
  },
  {
    path: '/addcustomer',
    name: 'addcustomer',
    component: () =>
      import(/* webpackChunkName: "page1" */ '../views/Page1.vue'),
  },
  {
    path: '/orders',
    name: 'orders',
    component: () =>
      import(/* webpackChunkName: "page2" */ '../views/Page2.vue'),
  },
  {
    path: '/page3',
    name: 'Page3',
    component: () =>
      import(/* webpackChunkName: "page3" */ '../views/Page3.vue'),
  },
  {
    path: '/editcustomer',
    name: 'Page4',
    component: () =>
      import(/* webpackChunkName: "page4" */ '../views/Page4.vue'),
  },
  {
    path: '/authentication',
    name: 'multistepform',
    component: () =>
      import(
        /* webpackChunkName: "MultiStepForm" */ '../views/MultiStepForm.vue'
      ),
  },

  {
    path: '/productdetail/:id',
    name: 'ProductDetail',
    component: () =>
      import(
        /* webpackChunkName: "ProductDetail" */ '../views/ProductDetail.vue'
      ),
  },
  {
    path: '/Success',
    name: 'Success',
    component: () =>
      import(/* webpackChunkName: "Success" */ '../views/Success.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () =>
      import(
        /* webpackChunkName: "Success" */ '../views/TermsandConditions.vue'
      ),
  },
  {
    path: '/formulairerh',
    name: 'formulairerh',
    component: () =>
      import(
        /* webpackChunkName: "Success" */ '../views/RhPage.vue'
      ),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () =>
      import(
        /* webpackChunkName: "Success" */ '../views/Contact.vue'
      ),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition)
      return savedPosition

    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    return { x: 0, y: 0 }
  },
})

// router.beforeEach((to, from, next) => {
//   console.log(to.name);
//   if (to.name == "authentication" && store.state.cart.products.length <= 0)
//     next({ path: "/" });
//   else next(false);
//   next();
// });

export default router
