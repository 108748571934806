<script>
export default { props: ['externalClass'] }
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg" width="26.325" height="26.314" viewBox="0 0 26.325 26.314"
    :class="externalClass"
  >
    <g id="MFOLJw.tif" transform="translate(4378.614 -549.622)">
      <g id="Group_1198" data-name="Group 1198" transform="translate(-4378.614 549.622)">
        <path
          id="Path_3351" data-name="Path 3351"
          d="M-4364.677,575.936h-1.542c-.075-.015-.149-.033-.225-.044-.717-.111-1.447-.171-2.151-.34a13.171,13.171,0,0,1-9.783-15.236,12.787,12.787,0,0,1,4.012-7.206,12.855,12.855,0,0,1,10.729-3.364,12.567,12.567,0,0,1,6.853,3.132,12.885,12.885,0,0,1,4.292,7.633c.086.495.136,1,.2,1.493v1.542c-.016.091-.035.181-.047.272-.062.475-.1.954-.188,1.424a12.859,12.859,0,0,1-3.566,6.78,12.837,12.837,0,0,1-7.09,3.711C-4363.676,575.819-4364.178,575.87-4364.677,575.936Zm8-5.6a11.569,11.569,0,0,0-.873-16.071,11.607,11.607,0,0,0-16.854,1.116,11.509,11.509,0,0,0,.19,14.943,9,9,0,0,1,2.043-3.813,9.179,9.179,0,0,1,3.606-2.445,5.578,5.578,0,0,1-1.011-8.289,5.535,5.535,0,0,1,7.743-.491,5.369,5.369,0,0,1,1.906,4.034,5.5,5.5,0,0,1-2.4,4.731A9.169,9.169,0,0,1-4356.674,570.337Zm-8.567,4.058c.267-.022.746-.046,1.221-.1a11.356,11.356,0,0,0,5.889-2.515.322.322,0,0,0,.1-.25,7.478,7.478,0,0,0-8.658-6.356,7.5,7.5,0,0,0-6.168,6.347.343.343,0,0,0,.105.27A11.715,11.715,0,0,0-4365.241,574.395Zm-.22-10.93a4.007,4.007,0,0,0,4.012-3.968,4.014,4.014,0,0,0-3.961-4.021,4.014,4.014,0,0,0-4.037,3.971A4.005,4.005,0,0,0-4365.461,563.465Z"
          transform="translate(4378.614 -549.622)" fill="#ec168d"
        />
      </g>
    </g>
  </svg>
</template>

<style lang="scss" scoped>

</style>
