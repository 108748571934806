<script>
export default { props: ['color'] }
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="11.174" height="30.474" viewBox="0 0 11.174 30.474">
    <path
      id="Path_54" data-name="Path 54"
      d="M198.142,253.97a9.462,9.462,0,0,0-1.667-5.4c-.314-.464-.646-.915-.953-1.384a5.573,5.573,0,0,1-.9-2.662c.435-.075.509-.378.5-.754-.015-.422,0-.846-.006-1.269a1,1,0,0,0-1.042-1.037q-1.491-.01-2.981,0a1.016,1.016,0,0,0-1.063,1.054c-.006.412.014.826-.006,1.237-.018.378.07.671.521.787-.1.436-.178.86-.294,1.274a8.383,8.383,0,0,1-1.438,2.518,9.757,9.757,0,0,0-1.834,5.824q.015,7.677,0,15.355c0,.127,0,.254,0,.381a2.146,2.146,0,0,0,2.562,1.985c.392-.082.771-.223,1.162-.308a.828.828,0,0,1,.473.04,2.908,2.908,0,0,0,2.341.132,2.007,2.007,0,0,1,1.569.011c.07.025.142.042.213.061a2.152,2.152,0,0,0,2.843-2.144Q198.147,261.822,198.142,253.97Zm-7.1-11.465h3.036v.966h-3.036Zm-2.46,8.343a12.207,12.207,0,0,1,1.626-2.744,6.984,6.984,0,0,0,1.246-2.982c.032-.2.054-.394.082-.6h2.037c.052.321.091.643.158.959a6.914,6.914,0,0,0,1.189,2.636,10.607,10.607,0,0,1,1.794,3.256c.161.566.271,1.145.411,1.751h-9.079A8.072,8.072,0,0,1,188.582,250.848Zm-.567,3.334h9.1v8.085h-9.1Zm7.483,16.639a11.381,11.381,0,0,0-1.127-.311,1.1,1.1,0,0,0-.653.08,2.342,2.342,0,0,1-2.33-.012,1,1,0,0,0-.626-.074c-.453.092-.892.253-1.342.367A1.114,1.114,0,0,1,188,269.829c-.017-2.145,0-4.291,0-6.436a.351.351,0,0,1,.027-.08h9.092c.005.115.013.217.013.32q0,2.98,0,5.961A1.161,1.161,0,0,1,195.5,270.821Z"
      transform="translate(-186.972 -241.461)" :fill="color || '#ec168c'"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
